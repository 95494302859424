import type { ListingItem } from 'api/homes/types'
import { Hometype } from 'libs/consts'
import { EventType, Events, GTMEcommerceItemVariant } from 'utils/analytics'

export function getItemVariantByHometype(hometype?: Hometype) {
	switch (hometype) {
		case Hometype.Apartment:
			return GTMEcommerceItemVariant.Apartment

		case Hometype.Room:
			return GTMEcommerceItemVariant.Room

		default:
			return '-'
	}
}

export function getCatalogScrollData(
	{ number, name, city, index, price, hometype, id }: Partial<ListingItem> & { index: number },
	eventName: string
) {
	return {
		name: Events.Catalog.VIEW_ITEM_LIST,
		params: {
			eventType: EventType.Ecommerce,
			ecommerce: {
				item_list_name: eventName,
				items: [
					{
						item_id: id,
						item_name: `${number || ''}, ${name || ''}, ${city?.title || ''}`,
						index,
						location_id: city?.title || '-',
						price,
						quantity: 1,
						item_variant: getItemVariantByHometype(hometype),
					},
				],
			},
		},
	}
}

export function getCatalogClickData(
	{ number, name, city, index, price, hometype, id }: Partial<ListingItem> & { index: number },
	eventName: string
) {
	return {
		name: Events.Catalog.SELECT_ITEM,
		params: {
			eventType: EventType.Ecommerce,
			ecommerce: {
				item_list_name: eventName,
				items: [
					{
						item_id: id,
						item_name: `${number || ''}, ${name || ''}, ${city?.title || ''}`,
						index,
						location_id: city?.title || '-',
						price,
						quantity: 1,
						item_variant: getItemVariantByHometype(hometype),
					},
				],
			},
		},
	}
}
