import type { EventData, Emitter } from './index'

// must be singleton
export class QueueDispatcher {
	queue: Array<[EventData['type'] | EventData['meta'], EventData['meta']?]> = []
	emitter: Emitter
	interval: number
	timerId?: ReturnType<typeof setInterval>

	constructor({ emitter, interval = 5000 }: { emitter: Emitter; interval?: number; pack?: number }) {
		this.interval = interval
		this.emitter = emitter
	}

	push(...args: Array<[EventData['type'] | EventData['meta'], EventData['meta']?]>) {
		if (this.queue.length === 0) {
			this.scheduleJob()
		}

		return this.queue.push(...args)
	}

	worker = () => {
		let count = this.queue.length

		while (count--) {
			const eventArgs = this.queue.shift() as [EventData['type'], EventData['meta']?]

			this.emitter(...eventArgs)
		}

		if (this.queue.length !== 0) {
			this.scheduleJob()
		}
	}

	scheduleJob() {
		setTimeout(this.worker, this.interval)
	}
}
