export async function createIntersectionObserve(
	callback: IntersectionObserverCallback,
	options?: IntersectionObserverInit
) {
	if (
		!(
			'IntersectionObserver' in window &&
			'IntersectionObserverEntry' in window &&
			'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
			'isIntersecting' in window.IntersectionObserverEntry.prototype
		)
	) {
		// @ts-ignore no need to fix, there's browser typing
		await import('intersection-observer')
	}

	return new IntersectionObserver(callback, options)
}
