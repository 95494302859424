import { parseCookies } from 'nookies'

import { LocalStorageKeys } from 'libs/consts'
import { getItem, updateItem } from 'utils/local-storage'

type AnalyticsSessionLS = {
	landingUrl?: string | null
	referrer?: string | null
	userId?: string | null
	userName?: string
	userEmail?: string
	userPhoneNumber?: string
}

type AnalyticsSessionCookies = {
	_ga?: string | null
	_gid?: string | null
	__jh_landing_url?: string | null
}

type AnalyticsSessionParams = {
	ga?: AnalyticsSessionCookies['_ga']
	gid?: AnalyticsSessionCookies['_gid']
	landing_page_url?: AnalyticsSessionCookies['__jh_landing_url']
	http_referer?: AnalyticsSessionLS['referrer']
	user_id?: AnalyticsSessionLS['userId']
	name?: AnalyticsSessionLS['userName']
	email?: AnalyticsSessionLS['userEmail']
	phoneNumber?: AnalyticsSessionLS['userPhoneNumber']
}

export function saveAnalyticsParams(params: Partial<AnalyticsSessionLS> = {}): void {
	const { userId = null, userName, userEmail, userPhoneNumber } = params

	const result: AnalyticsSessionLS = {
		userId,
		userName,
		userEmail,
		userPhoneNumber,
	}

	if (document.referrer) {
		result.referrer = document.referrer
	}

	updateItem(LocalStorageKeys.AnalyticsSession, result)
}

export function getAnalyticsParams(): AnalyticsSessionParams {
	if (typeof window === 'undefined') {
		return {}
	}

	const { _ga: ga, _gid: gid, __jh_landing_url: landing_page_url }: AnalyticsSessionCookies = parseCookies()
	const {
		referrer: http_referer,
		userId: user_id,
		userName: name,
		userEmail: email,
		userPhoneNumber: phoneNumber,
	} = getItem<AnalyticsSessionLS>(LocalStorageKeys.AnalyticsSession)

	return { ga, gid, http_referer, landing_page_url, user_id, name, email, phoneNumber }
}
