export enum EventType {
	Page = 'page',
	Send = 'send',
	Identify = 'identify',
	Ecommerce = 'ecommerce',
	LoadHotjar = 'load_hotjar',
}

export type EventData = {
	type: `${EventType}`
	meta: {
		name: string
		params?: {
			eventType?: 'page' | 'ecommerce' | 'send' | 'identify' | 'load_hotjar'
			[key: string]: string | unknown
		}
	}
}

export type DataAnalytics = {
	'data-click'?: string
	'data-change'?: string
	'data-part'?: string
	'data-action-to'?: string
	'data-event-name'?: string
	'data-scroll'?: string
}

export type Emitter = (type: EventData['type'] | EventData['meta'], meta?: EventData['meta']) => void

export enum GTMEventType {
	PageView = 'custom_pageview',
	CustomEvent = 'custom_event',
}

export enum GTMEcommerceItemVariant {
	Apartment = 'apartment',
	Room = 'room',
}
