import { logError } from 'infrastructure/logger'
import { LocalStorageKeys } from 'libs/consts'

type SetItem<T = unknown> = <NT = T>(key: LocalStorageKeys, data: NT, prefix?: string) => void

const PREFIX = '__jh_'

export const setItem: SetItem = function setItem(key, data, prefix = PREFIX): void {
	if (typeof window === 'undefined') {
		return
	}

	try {
		const json = JSON.stringify(data)

		window.localStorage.setItem(`${prefix}${key}`, json)
	} catch (err) {
		logError(err)
	}
}

export const updateItem: SetItem = function updateItem(key, data, prefix = PREFIX): void {
	if (typeof window === 'undefined') {
		return
	}

	const savedData = getItem(LocalStorageKeys.AnalyticsSession) || {}

	setItem(key, Object.assign({}, savedData, data), prefix)
}

export const getItem = function getItem<T>(key: LocalStorageKeys, prefix = PREFIX): T {
	// if it is called on server
	if (typeof window === 'undefined') {
		return {} as T
	}

	try {
		const data = window.localStorage.getItem(`${prefix}${key}`)

		if (data) {
			return JSON.parse(data)
		}
	} catch (err) {
		logError(err)
	}

	return {} as T
}

export function removeItem(key: LocalStorageKeys, prefix = PREFIX): void {
	if (typeof window === 'undefined') {
		return
	}

	try {
		window.localStorage.removeItem(`${prefix}${key}`)
	} catch (err) {
		logError(err)
	}
}
