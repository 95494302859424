import { logError } from 'infrastructure/logger'

import type { EventData } from './index'

export function setAnalyticsData(data: EventData['meta'] | Array<EventData['meta'] | undefined>): string | undefined {
	try {
		return JSON.stringify(data)
	} catch (err) {
		logError(err)
	}
}

export function getAnalyticsData(data: string): EventData['meta'] | EventData['meta'][] | undefined {
	try {
		return JSON.parse(data)
	} catch (err) {
		logError(err)
	}
}
