import { QueueDispatcher } from './QueueDispatcher'
import type { EventData, Emitter } from './index'
import { EventType, GTMEventType } from './index'
import { getAnalyticsParams } from './params'

const queueDispatcher = new QueueDispatcher({ emitter: emitAnalyticsEvent as Emitter })

export function emitAnalyticsEvent(meta: EventData['meta']): void
export function emitAnalyticsEvent(type: EventData['type']): void
export function emitAnalyticsEvent(type: EventData['type'], meta: EventData['meta']): void
export function emitAnalyticsEvent(type: EventData['type'] | EventData['meta'], meta?: EventData['meta']): void {
	if (typeof window === 'undefined' || !window.dataLayer) {
		const args: [EventData['type'] | EventData['meta'], EventData['meta']?] = [type, meta]

		queueDispatcher.push(args)
		return
	}

	const { user_id: _userId, name, email, phoneNumber } = getAnalyticsParams()

	if (typeof type !== 'string') {
		meta = type
		type = 'send'
	}

	switch (type) {
		case EventType.Page: {
			const eventData: Record<string, unknown> = {
				event: 'page_view',
				event_type: GTMEventType.PageView,
				user_id: _userId,
			}

			if (meta?.params?.dl) {
				eventData.dl = meta.params.dl
			}

			window.dataLayer.push(eventData)
			return
		}
		case EventType.Send: {
			if (!meta) {
				return
			}

			window.dataLayer.push({
				event: meta.name,
				event_type: GTMEventType.CustomEvent,
				user_id: _userId,
				...meta.params,
			})
			return
		}
		case EventType.Identify: {
			if (!meta) {
				return
			}

			window.dataLayer.push({
				event: 'identify',
				event_type: GTMEventType.CustomEvent,
				user_id: _userId,
				...meta.params,
			})
			return
		}

		case EventType.Ecommerce: {
			if (!meta) {
				return
			}
			window.dataLayer.push({ ecommerce: null })
			window.dataLayer.push({
				event: meta.name,
				event_type: 'ecommerce_event',
				user_id: _userId,
				name,
				email,
				phoneNumber,
				...meta.params,
			})
			return
		}

		case EventType.LoadHotjar: {
			window.dataLayer.push({
				event: type,
			})
			return
		}

		default:
			return
	}
}
