import type { StaticImageData } from 'next/image'
import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { logError } from 'infrastructure/logger'
import { createIntersectionObserve } from 'utils/create-intersection-observe'

type Props = React.HTMLAttributes<HTMLElement> & {
	src: string | { src: string; default?: StaticImageData }
}

const cache = new Map<string, Promise<string>>()

export const AsyncIcon = forwardRef<SVGSVGElement, Props>(function AsyncIcon({ src, ...props }, ref) {
	const rootRef = useRef<HTMLDivElement>(null)
	const [icon, setIcon] = useState('')
	useImperativeHandle(
		ref,
		() => {
			return rootRef.current?.firstChild as SVGSVGElement
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[icon]
	)

	// `src?.src` fixes tests
	const url = typeof src === 'string' ? src : src.src ?? src.default?.src

	useEffect(() => {
		const element = rootRef.current
		let observer: IntersectionObserver | null = null

		if (!element) {
			return
		}

		function handleChange([entry]: IntersectionObserverEntry[]) {
			if (!entry.isIntersecting) {
				return
			}

			function handleLoad(i: string) {
				const icon = !ref ? i : getIconWithRef(i, ref)
				setIcon(icon)
				if (element && observer) {
					observer.unobserve(element)
				}
			}

			if (cache.has(url)) {
				cache.get(url)?.then(handleLoad)
				return
			}

			const req = fetch(url)
				.then((r) => r.text())
				.catch((err) => {
					logError(err, { url })

					return err
				})

			cache.set(url, req)
			req.then(handleLoad)
		}

		async function createObserver() {
			observer = await createIntersectionObserve(handleChange)

			if (element) {
				observer.observe(element)
			}
		}

		createObserver()

		return () => {
			// workaround for tests (conditional call with `?` isn't working)
			if (observer && observer.disconnect) {
				observer.disconnect()
			}
		}
	}, [url, ref])

	return <div ref={rootRef} {...props} dangerouslySetInnerHTML={{ __html: icon }} />
})

function getIconWithRef(icon: string, ref: React.ForwardedRef<SVGSVGElement>): string {
	return icon.replace(/./g, function (v, i) {
		const insertIndex = 5

		return i === insertIndex - 1 ? v + `ref={${ref}}${' '}` : v
	})
}
