import type { RequestError } from 'infrastructure/legacy-api-transport'

import { setAnalyticsData, emitAnalyticsEvent } from './index'

enum Owners {
	HERO_GET_QUOTE_TAP = 'landlord_choose_button',
	TEAM_BUTTON_TAP = 'team_button',
	CITIES_SCROLL = 'cities_page',
	WWD_SCROLL = 'what_we_do_page',
	CASE_SCROLL = 'case_studies_page',
	CASE_BUTTON_TAP = 'case_studies_button',
	HIW_SCROLL = 'how_it_works_page',
	NATIONAL_SCROLL = 'national_scale_page',
	FAQ_SCROLL = 'faq_page',
	FAQ_TAP = 'faq_list',
	REVIEWS_SCROLL = 'reviews_page',
	TEAM_SCROLL = 'team_page',
	FORM_INTERACT = 'schedule_call_form',
	FORM_INPUT_INTERACT = 'schedule_call_field',
	FORM_SELECT_INTERACT = 'schedule_call_list',
	FORM_SUBMIT_TAP = 'schedule_call_button',
}

enum Home {
	ROOM_CARD_TAP = 'home_room_card_card',
	BOOK_TOUR_BUTTON_TAP = 'home_floating_block_button',
	FEATURES_SCROLL = 'home_feature_page',
	NEIGHBORHOOD_SCROLL = 'home_neighborhood_page',
	UTILITIES_SCROLL = 'home_utilities_page',
	ROOMMATES_SCROLL = 'home_roommates_page',
	MAPS_BUTTON_TAP = 'home_map_button',
	MAP_TAP = 'home_map_field',
	TAB_TAP = 'home_tabs',
	TOUR_TAP = 'home_3d_tour_field',
	HOME_PAGE = 'home_page',
	EXTENSION_POPUP = 'extensions_offer_pop_up',
	ADD_TO_CART = 'add_to_cart',
}

enum Gallery {
	ELEMENT_TAP = 'photos_gallery_action',
}

enum Journey {
	VIRTUAL_TOUR = 'virtual_tour_button',
	IN_PERSON_TOUR = 'in_person_tour_button',
	REFER_FRIENDS = 'refer_friends_link',
}

enum Catalog {
	QUICK_SEARCH_TAP = 'quick_search_button',
	QUICK_SEARCH_FIELD_INTERACT = 'quick_search_field',
	SEARCH = 'catalog_search',
	SORTING_TAP = 'sorting_button',
	APART_CARD = 'apart_card_card',
	APART_CARD_SCROLL = 'apart_card_scroll',
	MAP_TAP = 'map_control',
	BANNER_TAP = 'marketing_banner_button',
	LOAD_MORE_TAP = 'load_more_button',
	CLEAR_FILTERS_TAP = 'clear_filters_button',
	VIEW_ITEM = 'view_item',
	VIEW_ITEM_LIST = 'view_item_list',
	SELECT_ITEM = 'select_item',
	SHOW_FURNISHING_PRICE = 'show_furnishing_price',
}

enum Filters {
	MAIN = 'filters',
	BUTTON_TAP = 'filters_button',
	RADIO_TAP = 'filters_radio_button',
	LIST_TAP = 'filters_list',
	FIELD_INTERACT = 'filters_field',
}

enum Room {
	BUTTON_TAP = 'room_button',
	ROOM_PAGE = 'room_page',
	ADD_TO_CART = 'add_to_cart',
}

enum SignIn {
	BUTTON_CREATE_ACCOUNT_TAP = 'sign_in_button',
}

enum SignUp {
	BUTTON_TAP = 'sign_up_button',
	FIELD_INTERACT = 'sign_up_field',
	CHECKBOX_TAP = 'sign_up_check_box',
	PAGE = 'sign_up_page',
	GAUTH_SIGNUP = 'signUp',
}

enum Journey {
	CHOOSE_ROOM_BUTTON_TAP = 'choose_your_room_button',
	CHOOSE_ROOM_CARD_TAP = 'choose_your_room_card',
	RATE_TOUR_SLIDER = 'rate_tour_slider',
	RATE_TOUR_FIELD_INTERACT = 'rate_tour_field',
	RATE_TOUR_BUTTON_TAP = 'rate_tour_button',
	MI_BUTTON_TAP = 'mi_button',
	MI_FIELD_INTERACT = 'mi_field',
	UPLOAD_DOCS_BUTTON_TAP = 'upload_docs_button',
	UPLOAD_DOCS_FIELD_INTERACT = 'upload_docs_field',
	DOCUMENTS_UPLOAD = 'documents_document',
	MI_AGREE_CHECKBOX = 'agree_checkbox',
	MI_SUBMIT = 'mi_submit',
	MI_PAYMENT_FIELD_INTERACT = 'mi_payment_field',
	MI_PAYMENT_BUTTON_TAP = 'mi_payment_button',
	MI_PAYMENT_ERROR = 'mi_payment_error',
	MI_DOC_REQUESTED = 'upload_docs_requested',
	MI_DOC_REQUESTED_BUTTON_TAP = 'docs_requested_button',
	PRICE_HINT_BUTTON_TAP = 'price_hint_button',
	FINILIZE_AGREEMENT_CARD = 'finilize_agreement_card',
	FINILIZE_AGREEMENT_BUTTON = 'finilize_agreement_button',
	ADD_TO_CART = 'add_to_cart',
	VIEW_CART = 'view_cart',
	REMOVE_FROM_CART = 'remove_from_cart',
	BEGIN_CHECKOUT = 'begin_checkout',
	PURCHASE = 'purchase',
	ROOMMATE_INVITE = 'roommate_invite',
	REVIEW_ADD_ROOMMATE = 'review_add_roommate',
	REVIEW_DELETE_ROOMMATE = 'review_remove_roommate',
}

enum ReviewDetails {
	ALTERNATIVE_ROOM_CLICK = 'alternative_room_click',
	ALTERNATIVE_ROOM_SCROLL = 'alternative_room_scroll',
}

enum BookTour {
	PRICE = 'book_tour_price',
	HOTDEAL = 'book_tour_hotdeal',
	CONFIGURE = 'configure_booking_block',
	MEETING = 'book_meeting_side_menu',
	REVIEW = 'review_details_screen',
}

enum SkipTour {
	DISPLAY = 'skip_tour_display',
	ACTION = 'skip_tour_action',
}

enum PaymentPortal {
	DEFAULT_NAME = 'payment_window',
	UPCOMING_PAY = 'next_payment_block',
	FAILED_SCREEN = 'failed_payment_screen',
	FAILED_STATE = 'payment_failed',
	RECEIVED_STATE = 'payment_received',
}

enum MainPage {
	CITY_SEARCH = 'city_search_main',
	MOVEIN_SEARCH = 'movein_search_main',
	MOVEOUT_SEARCH = 'moveout_search_main',
	MISC_EVENTS = 'main_page_misc_events',
	SELECTOR = 'main_page_selector',
}

enum Map {
	MAIN = 'map_event',
}

enum Conversions {
	BOOK_TOUR = 'bookTour',
	SIGN_UP = 'signUp',
	START_JOURNEY = 'startJourney',
	APP_STARTED = 'AppStarted',
	APP_PAID = 'AppPaid',
	MEMBER_PAID = 'MemberPaid',
}

enum GoogleBusinessVertical {
	HOTEL_RENTAL = 'hotel_rental',
}

enum Hints {
	SELECT_HINT = 'selectHint',
	SHOW_HINTS = 'showHints',
	CONFIRM_DATES = 'confirmDates',
}

enum PreApproval {
	MAIN = 'pre_approval',
	CONTINUE = 'continue_pre_approval',
}

enum Listing {
	VIEW_UNIT_DETAILS = 'view_unit_details',
	VIEW_QUALIFICATION_REQUIREMENTS = 'view_qualification_requirements',
}

export const Events = {
	Owners,
	Home,
	Gallery,
	Journey,
	ReviewDetails,
	Catalog,
	VIRTUAL_PAGE: 'virtual_page',
	Filters,
	Room,
	SignUp,
	SignIn,
	BookTour,
	SkipTour,
	PaymentPortal,
	MainPage,
	Map,
	Conversions,
	GoogleBusinessVertical,
	Hints,
	PreApproval,
	Listing,
}

export const catalog = {
	banner: setAnalyticsData({
		name: Events.Catalog.BANNER_TAP,
	}),
}

export const signIn = {
	createAccount: setAnalyticsData({
		name: Events.SignIn.BUTTON_CREATE_ACCOUNT_TAP,
		params: {
			action_to: 'create_account',
			type: 'button',
			part: 'sign_in',
		},
	}),
}

export const signUp = {
	handleSubmitForm: (errors: RequestError['body'] = null) =>
		emitAnalyticsEvent({
			name: Events.SignUp.BUTTON_TAP,
			params: {
				errors,
				is_form_valid: !errors,
				part: 'sign_up',
				action: 'click',
				type: 'button',
				action_to: 'create_account',
			},
		}),
	first_name: setAnalyticsData({
		name: Events.SignUp.FIELD_INTERACT,
		params: {
			part: 'sign_up',
			type: 'field',
			action_to: 'first_name',
			name: 'first_name',
		},
	}),
	last_name: setAnalyticsData({
		name: Events.SignUp.FIELD_INTERACT,
		params: {
			part: 'sign_up',
			type: 'field',
			action_to: 'last_name',
			name: 'last_name',
		},
	}),
	email: setAnalyticsData({
		name: Events.SignUp.FIELD_INTERACT,
		params: {
			part: 'sign_up',
			type: 'field',
			action_to: 'email',
			name: 'email',
		},
	}),
	phone: setAnalyticsData({
		name: Events.SignUp.FIELD_INTERACT,
		params: {
			part: 'sign_up',
			type: 'field',
			action_to: 'phone',
			name: 'phone',
		},
	}),
	password: setAnalyticsData({
		name: Events.SignUp.FIELD_INTERACT,
		params: {
			part: 'sign_up',
			type: 'field',
			action_to: 'password',
			name: 'password',
		},
	}),
	subscribe: setAnalyticsData({
		name: Events.SignUp.FIELD_INTERACT,
		params: {
			part: 'sign_up',
			action: 'click',
			type: 'check_box',
		},
	}),
}

export const paymentPortal = {
	upcomingPay: setAnalyticsData({
		name: Events.PaymentPortal.DEFAULT_NAME,
		params: { action: 'open', fieldName: 'source', fieldValue: Events.PaymentPortal.UPCOMING_PAY },
	}),
	failedScreen: setAnalyticsData({
		name: Events.PaymentPortal.DEFAULT_NAME,
		params: { action: 'open', fieldName: 'source', fieldValue: Events.PaymentPortal.FAILED_SCREEN },
	}),
	failedState: {
		name: Events.PaymentPortal.DEFAULT_NAME,
		params: { action: 'view', fieldName: 'status', fieldValue: Events.PaymentPortal.FAILED_STATE },
	},
	receivedState: {
		name: Events.PaymentPortal.DEFAULT_NAME,
		params: { action: 'view', fieldName: 'status', fieldValue: Events.PaymentPortal.RECEIVED_STATE },
	},
	makePayment: setAnalyticsData({
		name: Events.PaymentPortal.DEFAULT_NAME,
		params: { action_to: 'pay' },
	}),
	cancelPayment: setAnalyticsData({
		name: Events.PaymentPortal.DEFAULT_NAME,
		params: { action_to: 'cancel_payment' },
	}),
	alerts: (fieldValue: string) =>
		setAnalyticsData({
			name: Events.PaymentPortal.DEFAULT_NAME,
			params: {
				fieldValue,
				action: 'open',
				fieldName: 'notification_text',
			},
		}),
}
